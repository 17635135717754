.timeline {
    border-left: 1px solid #23AAE1;
    position: relative;
    list-style: none;
}

.timeline .timeline-item {
    position: relative;
}

.timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .timeline-item:after {
    background-color: #23AAE1;
    left: -38px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
}

.swimelane{
    background: #10B82B;
    border-radius: 15px;
    margin-bottom: 5px;
    color: white;
}

#AgileTaskPriorities, #AgileTaskComplexities {
    width: fit-content;
    font-size: 1em;
    box-shadow: var(--box-shadow-agile) var(--box-shadow-agile-color);
    margin: 20px auto 20px auto;
}

#AgileTaskPriorities .table-list-header, #AgileTaskComplexities .table-list-header {
    text-transform: uppercase;
    text-align: center;
    display: block;
    border-color: var(--blue-border-gradient-match);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    font-size: .9em;
}

#AgileTaskPriorities .agile-bg-red {
    border-color: var(--blue-border-gradient-match);
    background-color: var(--background-red);
}

#AgileTaskPriorities .agile-bg-green {
    border-color: var(--blue-border-gradient-match);
    background-color: var(--background-green);
}

#AgileTaskPriorities .agile-bg-cream {
    border-color: var(--blue-border-gradient-match);
    background-color: var(--background-cream);
}

#AgileTaskPriorities .agile-row  .agile-table-list-title strong, #AgileTaskComplexities .agile-row  .agile-table-list-title strong {
    text-align: center !important;
    display: block;
    color: var(--pp-black);
}

#AgileTaskComplexities .agile-table-list-details {
    font-size: .8em;
}

.agile-table-list-details {
    font-size: .9em;
    text-align: center;
    color: var(--pp-black);
}

.agile-row .agile-table-list-details ul li {
    display: list-item;
}

#TaskComplexitiesGradient {
    background: #EBFC00;
    background: -moz-linear-gradient(top,  #EBFC00 0%, #FFA84C 50%, #FF0F0F 100%);
    background: -webkit-linear-gradient(top,  #EBFC00 0%,#FFA84C 50%,#FF0F0F 100%);
    background: linear-gradient(to bottom,  #EBFC00 0%,#FFA84C 50%,#FF0F0F 100%);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.agile-row {
    padding: 10px 3px 7px 3px;
    border-bottom: 1px solid var(--blue-border-gradient-match);
    border-right: 1px solid var(--blue-border-gradient-match);
    border-left: 1px solid var(--blue-border-gradient-match);
    position: relative;
}

.agile-row .table-list-details {
    color: var(--pp-black);
    font-weight: normal;
    margin-top: 5px;
    line-height: 20px;
}

.agile-row .table-list-details li {
    list-style-type: none;
}

.agile-row:last-child, #AgileTaskPriorities .agile-row:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.tooltip-agile-row{
    position: absolute;
    top: 8%;
    z-index: 9;
    right: -73%;
    background: white;
}

.sun-editor-editable {
    font-family: system-ui, -apple-system !important;
}

/* KANBOARD PLUGIN - AGILE INDICATORS CSS FILE */

:root {
    --pp-blue: #007AC9;
    --pp-blue-alt: #00ADFF;
    --pp-red: #B71234;
    --pp-red-alt: #DA004C;
    --pp-red-alt-2: #AE003D;
    --pp-black: #000000;
    --pp-grey: #4D4D4D;
    --pp-white: #FFFFFF;
    --pp-green: #2EA02E;
    --pp-orange: #FF6500;
    --border-radius: 3px;
    --button-blue-gradient: linear-gradient(to bottom, #7892C2 80%, #476E9E 100%);
    --blue-border-gradient-match: #476E9E;
    --box-shadow-agile: 1px 1px 3px 0;
    --box-shadow-agile-color: #476E9E;
    --background-red: #FF000054;
    --background-green: #00800078;
    --background-cream: #FFA5006E;
}

.display-none, .d-none { display: none; }

.tasktimeline{
    background: #ECEFF2;
    border: 1px solid #D5D5D5;
    padding:10px
}

.ͼ1 .cm-content {
    width: 80%!important;
    flex-shrink: unset!important;
    white-space: unset!important;
}

.theme-header {

    z-index: 97 !important;
}


.swimlanemyTask{
    background: #29AAE1;
    border-radius: 0px;
    padding:5px;
    border: 1px solid black;

}
.cpointer{
    cursor: pointer;
}

.swimlanemyTaskButton{
    height: 20px; width: 20px; padding: 0px; margin-top: 2px;
}
.fS18{
    font-size: 18px;
}
iframe#webpack-dev-server-client-overlay{display:none!important}

#MoveElement {
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
}

#MoveElementHeader {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background-color: #2196F3;
    color: #fff;
}

.colorPickergeneral{
    width: 28px; height: 28px; margin-right: 14px; margin-bottom: 14px; transform: scale(1); transition: transform 100ms ease 0s;
}

.colorPickergeneral:hover{
    transform: scale(1.2)
}


.colorPickerColor{
    background: transparent;
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    outline: none;
    border-radius: 50%;
    transition: box-shadow 100ms ease 0s;
    border: 1px solid lightgrey;
}

.colorPickerColor:hover::after {
    content: attr(data-title);
    font-size: 14px;
    border:1px solid blue;
    background-color:white;
    color:black;
    padding: 10px;
    border-radius: 5px;
    position:absolute;
    top:-45px;
    left:-25px;
    width: max-content;
    z-index: 10005;
}


.circle-picker-general{
    display: flex; flex-wrap: wrap; margin-right: -14px; margin-bottom: -14px;
}
@import url("gantt-task-react/dist/index.css");

._3_ygE {
    display: none !important;
}

._34SS0 {
    display: none !important;
}
._3_ygEs {
    font-family: Arial, Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue'; font-size: 14px;
    border: 1px solid transparent;
    background: transparent;
}

._2eZzQ{
    height: 25px; margin-top: 10px;
}

._3ZbQT{
    font-family: Arial, Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue'; font-size: 14px;
}

._1nBOt{
    height: 48px
}
._34SS0s{
    height:50px;
    text-overflow: ellipsis;
}
/*.circle {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: blue;
    border-radius: 20px;
}
.container {
    display: none;
    width: 200px !important;
    position: absolute !important;
}*/

.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "\25B6";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}
a.MenuItemahref:link {color: black;}
a.MenuItemahref:visited {color: black;}
a.MenuItemahref:hover {color: black;}

.cardAvatarMini{
    width: 30px !important;
    height: 30px !important;
    font-size: 12px !important;
    display: inline-flex !important;
    margin-left: 0;
    margin-right: 5px;
}
.alertI {
    padding: 20px;
    border-color: #f44336 !important;
    position: absolute !important;
    top: 70px !important;
    right: 25px !important;
    z-index: 1011 !important;
    min-width: 370px !important;
    width: 570px !important;
}

.closebtnI {
    margin-left: 15px;
    color: black;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.closebtnI:hover {
    color: black;
}
