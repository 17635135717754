.grayborder15 {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
}

/** SaveComments Zeile 44 
components issue.tsx Zeile 62, 88
*/
