.boxDrag {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: white;
    width: 320px;
    height: max-content;
}

.header{
    background-color: gray;
    cursor:move
}
.draggable-header {
    display: flex;
    float:right;
    align-items: center;
    cursor: grab;
    padding: 10px;
    border-radius: 4px;
    background-color: #f7f7f7;
}

.draggable-header:active {
    cursor: grabbing;
}

.move-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
