#collasible-nav-dropdown_dashboard_projects::after{
    display:none;
}

.ContentWrapper:has(.ticketsystem){
    background: none !important;
}


.teamMember{
   /* position: absolute  !important;;
    right: 55px;
    top: 24px;
    z-index: 0;*/
    margin-left: -10px !important;
    cursor: pointer;
}


.legende{
    margin-top: 20px;
    margin-right: 40px;
}

.totalPie{
    position: absolute;
    z-index: 9;
    margin-top: 45%;
    margin-left: 40%;
    text-align: center;
}

#piechart {
    position: relative;
    width: 250px;
    height: 250px;
    margin-left: 100px;
    margin-top: 100px;
}

.piece {
    position: absolute;
    width: 250px;
    height: 250px;
    clip: rect(0px, 250px, 250px, 125px);
    border-radius: 125px;
}

.piece-inner {
    position: absolute;
    width: 250px;
    height: 250px;
    clip: rect(0px, 125px, 250px, 0px);
    border-radius: 125px;
    -webkit-backface-visibility: hidden;
}


.chart-text {
    /*font: 16px/1.4em "Montserrat", Arial, sans-serif;*/
    fill: #000;
    -moz-transform: translateY(0.25em);
    -ms-transform: translateY(0.25em);
    -webkit-transform: translateY(0.25em);
    transform: translateY(0.25em);
}

.chart-number {
    font-size: 0.5em;
    line-height: 1;
    text-anchor: middle;
    -moz-transform: translateY(-0.25em);
    -ms-transform: translateY(-0.25em);
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
}

.chart-label {
    font-size: 0.2em;
    text-transform: uppercase;
    text-anchor: middle;
    -moz-transform: translateY(0.7em);
    -ms-transform: translateY(0.7em);
    -webkit-transform: translateY(0.7em);
    transform: translateY(0.7em);
}

@media screen and (max-width: 1199px) {
    .dashbaordCard {
        padding:0px !important
    }

    .dashbaordCardLeft{
        padding-right: 0px !important;
        margin-bottom: 10px !important;

    }

    .dashbaordCardLeftinner{
        margin-right: 0px !important;
    }

    .dashbaordInfoCards{
        margin-bottom: 10px;
    }
}

