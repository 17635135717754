/** Farben */



.primary-blue {
    color: #135e7e;
}

.primary-blue-bg[type=submit]:hover {
    background-color: #6391aa;
    color: #ffffff;
}


.secondary-blue-bg {
    background-color: #6391aa;
    color: #ffffff;
}

.secondary-blue {
    color: #6391aa;
}


/**Grunddesign */

header.fixed-top {
    border-bottom: solid 2px #135e7e;
}


.dropdown-menu[data-bs-popper] {
    top: 170% !important;
}

.menu-link {
    color: rgb(0 0 0 / 64%);
    text-decoration: none;
    font-size: 1.25em;
    padding: .5em 1em;
}

.menu-link:hover {
    color: #135e7e;
}

.active {
    color: #135e7e;
}

main > .container {
    padding: 190px 15px 0;
}

.footer_line {
    padding: 10px;
}

/** Home */

.userid {
    display: none;
}

.maincontent {
    display: none;
}

.profileimg {
    width: 100%;
    display: block;
}

.profile-images {
    position:absolute;
    top: 190px;
}

.row.time-table:nth-child(2n) {
    background-color: var(--bs-light);
}

.profil-top {
    margin-top: 130px;
}

img.profile_img.mb-3 {
    width: 30%;
    display: block;
}



.onlinebowl {
    width: 15px;
    height: 15px;
    display: inline-block;
    transform: translateY(2px);
    margin-right: 5px;
    border-radius: 100%;
}

/** Login */

.login-logo {
    width: 275px;
    height: auto;
}

/** Admin */
.admin-btn:hover {
    color: #135e7e;
}
.form-control1 {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-select1 {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-control1:disabled, .form-control1[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.editbtn {
    padding: 5px 10px 1px;
}

.time_output p {
    margin: 0;
    text-align: end;
}

.time_output p strong {
    float: left;
}

.time_output p:after {
    content: "";
    display: block;
    clear: both;
}

.edited::after {
    content: "*";
    font-size: 1.2em;
    color: red;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #135e7e !important;
}
.printable {
    display: none;
}
.nonprint {
    display: block;
}
.break-page {
    display: none;
}

@media only screen and (min-width: 768px) {
    .maincontent {
        display: block;
    }
}

@media print {
    .printable {
        display: block;
    }
    .print {
        display:block;
    }
    .nonprint {
        display: none;
    }
    .line {
        border-bottom: solid 1px;
    }
    .headline.print {
        position: absolute;
        top: 150px;
        left: 0;
    }
    .maincontent, .printcontent {
        display: block;
        top: 200px;
        position: absolute;
        width: 100%;
        left: 0;
        background: transparent !important;
        box-shadow: none !important;
    }
    .printcontentmonth {
        display: block;
        top: -180px;
        position: relative;
        width: 100%;
        left: -120px;
        background: transparent !important;
        box-shadow: none !important;
    }
    .maincontent .time-table:nth-child(2n) {
        background-color: #e0e0e0;
    }
    .time_output {
        position: absolute;
        left: 50px;
        top: 400px;
    }
    footer {
        position: absolute;
        bottom: 0;
    }
    .break-page {
        page-break-after: always;
        margin-bottom: 200px;
    }
}
