
.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 999999999;
    left: 245px;
    margin-top: -30px;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
}

.tooltips{
    position: absolute;
    margin-top: -10px;
    z-index: 1007;
}


/* Add Animation */
@keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}
