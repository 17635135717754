.inputGroupExtra{
    padding: 0 !important;
    height: 40px;
}

.inputNoBorder{
    border:0 !important;
}

.IconPlus{
    background: white !important;
    border-radius: 19px !important;
    color:#317391 !important;
    border-color: #317391 !important;
    z-index:99;
    height: 30px !important;
    width: 30px !important;
    text-align: center !important;
    padding: 2px !important;
    margin-top: 4px !important;
    margin-right: 4px !important;
    cursor: pointer;
}

.w250{
    width: 250px !important;
}

#registration-tabs-tab-personal,#registration-tabs-tab-namebadge, #registration-tabs-tab-registrationDetails, #registration-tabs-tab-stripe, #registration-tabs-tab-end{
    background:lightgrey;
    color:black;
    cursor:unset
}

#registration-tabs-tabpane-registrationDetails, #registration-tabs-tabpane-namebadge, #registration-tabs-tabpane-personal, #registration-tabs-tabpane-stripe {
    padding-top: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.nav-tabs .nav-link.active {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
}

.invalid{
    border: 2px solid red !important;
}

.valid{
    border: 2px solid green !important;
}

/*
.invalid::after {
    content: "Bitte Auffüllen";
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid red;  !* Corrected color spelling *!
    background-color: white;
}
*/


#registerCode.highlightErrorMessage {
    border: solid red 3px;
}
