.message {
    position: absolute;
    top: 1%;
    left: 36%;
}

.error {
    font-size: 20rem;
    text-align: center;
    margin: 0;
}

.rotate {
    position: absolute;
    top: 40%;
    left: 8%;
    background: none;
    -webkit-transform-origin: 0 20px 0;
    -moz-transform-origin: 0 20px 0;
    -ms-transform-origin: 0 20px 0;
    -o-transform-origin: 0 20px 0;
    -webkit-transform: rotate(307deg);
    -moz-transform: rotate(307deg);
    -ms-transform: rotate(307deg);
    -o-transform: rotate(307deg);
    font-family: 'Lato', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    margin: 2% 0 2% 0;
    text-shadow: 0px 3px 0px #7f8c8d;
}
