/*
Material Icons and symbols
 */
@import url('material-icons/iconfont/material-icons.css');
@import url('material-symbols');


/*
Bootstrap 4
 */

@import url("css/bootstrap.min.css");

/*
Bootstrap 5
 */

@import url("css/bootstrap5.min.css.scss");
/*
Base css with all general settings
 */
@import "css/base.css";

/*
Sidebar css with all style to sidebar
 */
@import "css/sidebar.css";

/*
Footer css with all style to footer
 */
@import "css/footer.css";

/*
Modal css with all style to modal
 */
@import "css/modal.css";

/*
React Page CSS with all style to ract-page
 */
@import "css/react-page.css";

/*
Scrollbar CSS with all style to scrollbar
 */
@import "css/scrollbar.css";

/*
Tooltip CSS with all style to tooltip
 */
@import "css/tooltip.css";

/*
Modul Masonry CSS with all style to masonry
 */
@import "css/module/masonry.css";

/*
Modul Hexagon CSS with all style to hexagon
 */
@import "css/module/hexagon.css";

/*
landing page
*/
@import "css/landingpage.css";

/*
fonts
*/
@import "css/fonts.css";

.alert-box {
    z-index:999999;
}

body {
    background-image: url(https://cdn.wum-solution.de//WUM/2023/12/17/di/IMG-5525.JPG) !important;
}
.Login {
    padding-bottom: 15px !important;
}

.opacityunset{
    opacity: unset !important;
}


.btnBack{
    border-radius: 34px;
    margin-bottom:20px;
    opacity: 1;
    height: 67px;
    width: 350px;
}

#FavoButton{
    display:none  !important;
}

.closemenu {
    height: 30px;
}

.se-read-only {
    background-color: var(--bs-secondary-bg);
}

@media (max-width: 767px) {
    .input-group {
        flex-wrap: wrap; /* Wrap the input group on mobile screens */
    }

    .input-group-text {
        width: 100%; /* Make the label span the full width on mobile */
    }
}

.required::after {
    content: " *";
    color: red;
    font-weight: bold;
    margin-left: 5px;
}

input[required] ~ label {
    color: red;
    background-color: red;
}