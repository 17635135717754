

.first{
    color:white !important;
}



.btn-primary{
    background-color: #015b91;
    border-color: #015b91;
    color:white;
}


.col .ga-item img, .ga-item img {
    object-fit: scale-down;
}

.colMax{
    max-width: 270px !important;
}




.moduleNav{
    display:none;
    margin-top: 0px;
    height: calc(100vh - 0px);
    position: fixed;
    left: 0;
    z-index: 1010;
    top: 0;
}







/*.sun-editor .se-toolbar{
    position: fixed !important;
    bottom: 65px !important;
    right: 80px;
    top: unset !important;
    z-index: 999999999999999999999;
}*/

.formControl{
    padding-bottom: 40px;
    background-color: white;
}
.settingsWebBuilderModul {
    position: fixed;
    z-index: 99999999999999;
    background: white;
    border: 1px solid black !important;
    width: 18%;
    left: 10px;
    top: 70px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-height: 60%;
    overflow: scroll;
}



#Teaser{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    transition: opacity .4s ease;
    opacity: 0;
    background: hsla(0,0%,100%,.95);
    text-align: center;
    color: rgba(0,0,0,.97);
    display: inline-block;
    padding: 12px 24px;
    margin: 0 auto;
    border-radius: 12px 12px 0 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: .15em;
    box-shadow: 0 -5px 5px rgb(0 0 0 / 22%);
    pointer-events: none;
}

.vmiddle{
    vertical-align: middle;
}

.bgunset {
    background: unset !important;
}


.freeWrapper{
    position: absolute;
    left: 0px;
    z-index: 9999;
    width: 99%;
    top: 0;
    bottom: 0;
    height: 100%;
    min-height: 100%;
    overflow: auto;
}