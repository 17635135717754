:root {
    --event-calendar-standard-color: #FDFDFD;
    --event-calendar-header-color: #3EB4CF;
    --event-calendar-standard-font-family: "Raleway";
    --event-calendar-accent-font-family: "Days One";
    --event-calendar-daily-color: #4687A8;
    --event-calendar-location-bar-background: #D4E5ED;
    --event-calendar-dark-color: #272727
}

.event-calendar {
    font-family: var(--event-calendar-standard-color);
    background: white;
}

/* styling of the header compartment */
.event-calendar .event-calendar-header {
    background: var(--event-calendar-header-color);
    color: var(--event-calendar-standard-color);
    font-family: var(--event-calendar-accent-font-family);
    font-vertical-align: top;
    font-size: 22px;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
}
/* styling of days of header */
.event-calendar .event-calendar-header-date {
    font-size: 18px;
}

/* styling of the tabs (selected) */
.event-calendar .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected{
    background: #DFF1F6;
    color: var(--event-calendar-dark-color)!important;
}
/* styling of the tabs (general and not selected) */
.event-calendar .MuiTabs-flexContainer, .event-calendar .MuiTabs-scrollButtons, .event-calendar .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    background: var(--event-calendar-dark-color);
    color: #DFF1F6!important;
    font-size: 14px;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: unset;

}

/* styling of the timeframe (15:00 - 17:00 */
.event-calendar .event-calendar-timeframe-box{
    background: #1F4174;
    color: var(--event-calendar-standard-color);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-variant: small-caps;
    padding-top: 4px;
    padding-bottom: 4px;
}

/* styling of the day headers which contain day headline */
.event-calendar .daily-header {
    text-align: center;
    background: var(--event-calendar-daily-color);
    color: var(--event-calendar-standard-color);
    font-size: 18px;
    font-weight: bold;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-variant: small-caps;
}

/* styled event-headline */
.event-calendar .event-headline {
    font-size: 22px;
    color: var(--event-calendar-dark-color);
    text-align: center;
    font-variant: small-caps;
    font-weight: 600;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
/* styled event-description */

.event-calendar .event-short-description {
    font-size: 16px;
    font-variant: small-caps;
    text-align: center;
    color: var(--event-calendar-dark-color);
    font-weight: 600;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

/* styling of the session headlines on the overview  */
.event-calendar .session-headline {
    font-size: 22px;
    color: var(--event-calendar-dark-color);
    text-align: center;
    font-variant: small-caps;
    font-weight: 600;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

/* styling of the session description on the overview  */
.event-calendar .session-description {
    font-size: 16px;
    font-variant: small-caps;
    text-align: center;
    color: var(--event-calendar-dark-color);
    font-weight: 600;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

/* styling of detail view sessions and there the session headline */
.event-calendar .daily-header .session-headline {
    color: var(--event-calendar-standard-color);
    font-size: 22px;
    font-weight: bold;
    font-variant: small-caps;
}

/* styling of detail view sessions and there the session description */
.event-calendar .daily-header .session-description {
    color: var(--event-calendar-standard-color);
    font-size: 18px;
    font-weight: 600;
    font-variant: small-caps;
}

/* styling of the location bar */
.event-calendar .location-bar {
    background: var(--event-calendar-location-bar-background);
    font-variant: small-caps;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: 600;
    font-size: 16px;

}


.event-changed-warning {
    background: #FFD646;
    font-variant: small-caps;
    font-weight: 600;
    text-align: center;
    color: var(--event-calendar-dark-color);
    font-size: 14px;
    padding-top: 4px;
    padding-bottom:  4px;

}

.event-changed-removed {
    background: #C70000;
    font-variant: small-caps;
    font-weight: 600;
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom:  4px;
}

/* removes styling of the  calendar */
.event-calendar a.href-no-highlight {
    color: unset;
    text-decoration: none;
}

/* styles the background button */
.event-calendar .event-button-back {
    background: var(--event-calendar-dark-color);
    color: var(--event-calendar-standard-color);
    font-size: 17px;
    font-weight: bold;
    font-variant: small-caps;
    border-radius: 0;
}

.event-calendar .event-button-back .material-icons-outlined  {
    font-variant: normal;
}


.event-calendar .abstract-text {
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    padding-top: 2em;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 2em;
}

.event-calendar .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
    display: none;
    visibility: hidden;
}

.event-calendar h3, .event-calendar p {
    margin-bottom: 0;
    padding-bottom: 0;
}

.event-calendar a {
    transition: all 0.2s ease-in-out;
}

.event-calendar a:active {
    background-color: #ddd;
    transform: scale(0.95);
}

/* Splashscreen (originating from jako, should be reworked at some point */

.splashscreen h1, .splashscreen h2, .splashscreen p {
    margin: 0;
    padding: 0;
}

.splashscreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FDFDFD;
    height: 100%;
    padding-top: 3em;
    font-family: Raleway, system-ui;
    color: #272727;
    text-align: center;
    box-sizing: border-box;

}

.splashscreen h2 {
    font-weight: 700;
    font-size: 24px;
    font-variant: small-caps;
    padding: 0.3em;
}


.splashscreen .title {
    font-family: "Days One", system-ui;
    line-height: 35px;
}

.splashscreen .title-top {
    font-size: 28px;
}

.splashscreen .title-middle {
    font-size: 50px;
}

.splashscreen .title-bottom {
    font-size: 30px;
}

.splashscreen .content {
    display: flex;
    font-size: 16px;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 1.5em;
}

.splashscreen p {
    font-weight: 500;
    line-height: 30px;
}

.splashscreen span {
    font-weight: 700;
}

.splashscreen button {
    background: #4687A8;
    color: #FDFDFD;
    width: 100%;
    height: 50px;
    margin-top: auto;
    border: none;
    font-family: Raleway, system-ui;
    font-variant: small-caps;
    font-size: 18px;
    font-weight: 700;
    padding: 1em;
}