/* General Board Styling */
.issue-board {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.issue-list {
    min-width: 380px; /* Remove fixed width */
    min-height: 300px;
    margin: 0 10px; /* Adjust margin for spacing */
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* List Header Styling */
.issue-list h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

/* Issue Card Styling */
.issue-card {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    max-height: 150px;
    overflow: hidden;
}

.issue-card h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.issue-card p {
    margin: 0;
}

/* Optional: Assignee Styling */
.issue-card .assignee {
    font-style: italic;
    color: #999;
}

/* List Header Styling */
.issue-list-header {
    background-color: #f0f8ff; /* Light blue background */
    padding: 15px 20px; /* Increased padding */
    border-bottom: 2px solid #ddd; /* Slightly thicker border */
}

/* Issue Card Styling */
.issue-card {
    background-color: #f7f7f7; /* Light gray background */
    border-radius: 8px; /* More rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


/* List Header Styling */
.issue-list-header {
    background-color: #e6f2ff; /* Darker blue background */
    padding: 10px 15px; /* Reduced padding */
    border-bottom: 1px solid #ddd; /* Thinner border */
}

/* Issue Card Styling */
.issue-card {
    height: 100px; /* Reduced height */
    padding: 5px; /* Reduced padding */
    border-radius: 6px; /* More rounded corners */
}

.issue-card-title {
    font-size: 14px; /* Reduced font size */
    font-weight: bold;
}

.issue-card-description {
    font-size: 12px; /* Reduced font size */
    margin-bottom: 0; /* Remove margin below description */
}



