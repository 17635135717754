
.masonry {
    display: block;
    flex-flow: column wrap;
    align-content: space-between;
    height: 665px;
}

.masonry::before,
.masonry::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
}
.masonry .brick {
    box-sizing: border-box;
    width: 33%;
    margin-bottom: 2%;
    padding: 1em;
}
/* Masonry grid */
.masonry {
    transition: all .5s ease-in-out;
    column-gap: 30px;
    column-fill: initial;
}

/* Masonry item */
.masonry .brick {
    margin-bottom: 30px;
    display: inline-block; /* Fix the misalignment of items */
    vertical-align: top; /* Keep the item on the very top */
}

/* Masonry image effects */
.masonry .brick img {
    transition: all .5s ease-in-out;
    backface-visibility: hidden; /* Remove Image flickering on hover */
}

.masonry .brick:hover img {
    opacity: .75;
}

/* Bordered masonry */
.masonry.bordered {
    column-rule: 1px solid #eee;
    column-gap: 50px;
}

.masonry.bordered .brick {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #eee;
}

/* Gutterless masonry */
.masonry.gutterless {
    column-gap: 0;
}

.masonry.gutterless .brick {
    margin-bottom: 0;
}

/* Masonry on tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .masonry {
        column-count: 2;
    }
}

/* Masonry on big screens */
@media only screen and (min-width: 1024px) {
    .desc {
        font-size: 1.25em;
    }

    .intro {
        letter-spacing: 1px;
    }

    .masonry {
        column-count: 3;
    }
}


.masonry .container {
    padding:0 !important;
}

.masonry .module {
    margin:0
}
.masonry figure {
    margin: 0 0 0;
}
figcaption {
    display: none;
}

.hover {
    overflow: hidden;
    position: relative;
    padding-bottom: 70.7%;
}

.hover img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
}

.hover-content {
    position: relative;
    z-index: 99;
}

.hover-1 img {
    width: 100%;
    position: absolute;
    top: 0;
    transition: all 0.3s;
}

.hover-1-content {
    position: absolute;
    top: 84%;
    left: 0;
    z-index: 99;
    transition: all 0.4s;
    padding-bottom: 18em;
    width: 100%;
}


.hover-1-description {
    transform: translateY(0.5rem);
    transition: all 0.4s;
    opacity: 0;
}

.hover-1:hover .hover-1-content {
    top: 50%;
}

.hover-1:hover .hover-1-description {
    opacity: 1;
    transform: none;
}

.hover-1:hover img {
    left: 0;
}

.hover-1:hover .hover-overlay {
    opacity: 0;
}



@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        flex: 0 0 auto;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        flex: 0 0 auto;
    }
}
