.whitepad {
    background-color: white;
    padding: 15px;
}
/**
 * labeloverview.tsx zeile 129 (oben)
 * labeloverview.tsx zeile 245 (unten)
 */

.colors {
    background-color: rgb(26, 25, 25);
    color: #fff;
}
