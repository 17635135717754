.padmarcolor {
    background-color: White;
    padding: 15px;
    margin-top: -15px;
}
/**
 * overview.tsx Zeile 33 37 46 oben
 * overview.tsx Zeile 38 unten
 */

.padcolor {
    background-color: white;
    padding: 15px;
}
