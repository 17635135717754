.DC_Title {
    min-height: 50px;
}

.DC_Text {
    height: 110px;
}
.DC_Button {
    width: 100%;
}

.DC_Card {
    width: 18rem;
}

/* form content element */
/* TODO : Move to seperate file */
.react-page-editable {
    border: 1px dashed lightgray;
    letter-spacing: 4px;
}
.settings-tab {
    background-color: #3770f8;
    border-top-right-radius: 10px;
    border: 1px solid;
    padding-right: 5px;
    border-color: #3770f8;
}
/*.settings-tab .bi-trash:after

{
    
    content: "";
    position: absolute;
    display: block;
    margin-left: 25px;
    top: 0px;
    width: 0px;
    height: 0px;
    border-bottom: solid 100px #3870F7;
    border-right: solid 100px transparent;
}*/

/*#elements .drag-icon:before

{
    
    content: "";
    position: absolute;
    display: block;
    margin-left: -100px;
    top: 0px;
    width: 0px;
    height: 0px;
    
    border-bottom: solid 100px #3870F7;
    border-left: solid 100px transparent;
}*/
.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.grabbable {
    border-top-left-radius: 10px;
}

.tab-content {
    background-color: #f5f9fa;
}

.widget-selection-box {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #f5f9fa;
    align-items: center;
}
.react-page-editable .react-page-cell-insert-new:hover {
    background: none !important;
}
.react-page-cell-insert-new .react-page-cell-insert-new-icon:hover {
    background-color: #3770f8 !important ;
}
