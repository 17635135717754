.elementsIMg {
    width: 100%;
    height: 250px;
    margin-right: 20px;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
}

.elementsIMghidden {
    visibility: hidden;
}
.vh80 {
    margin: 0 !important;
    height: 80vh;
}

.pl1point5em {
    padding-left: 1.5em !important;
}

.w100 {
    width: 100% !important;
}

.hideScrollbar {
    -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
    scrollbar-width: none !important; /* for Firefox */
    overflow-y: scroll !important;
}

#t1::-webkit-scrollbar {
    display: none;
}

#t1:focus {
    outline: none;
}

.visitor-profile-popup {
    top: 0 !important;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}

#sidebarCol {
    margin-top: 6px;
    height: calc(100vh - 10px);
}

#wrapperContent {
    padding: 0px;
    margin-top: 0px;
    overflow-y: scroll;
    height: calc(100vh);
    background-color: #f4f7fe;
    width: 100%;
}

.theme-header {
    position: absolute;
    top: 0;
    background: white;
    color: #3d5170;
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
        0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 20px;
    height: 60px;
    padding: 10px;
    padding-right: 20px;
    width: 100%;
    margin-left: -270px;
    z-index: 1008;
    right: 10px;
}

.theme-flags {
    float: right;
    margin-right: 20px;
}

.theme-avatar {
    float: right;
    position: relative;
}

.theme-footer {
    position: absolute;
    bottom: 0;
    background: white;
    color: #3d5170;
    border-top: 1px solid rgba(173, 173, 173, 0.2);
    /*box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);*/
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-left: -270px;
    height: 61px;
    padding: 20px;
    z-index: 1008;
    right: 10px;
}

.react-contextmenu-wrapper {
    margin-top: 20px;
}

.flex {
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .pro-sidebar {
        width: 80px !important;
        min-width: 80px !important;
    }
}

.avatarName {
    padding-top: 10px;
    float: left;
    padding-left: 10px;
}

.elementActive {
    padding: 10px;
}

.elementDeactive {
    background: repeating-linear-gradient(
        45deg,
        #f8cecc,
        #fff 3px,
        #fff 3px,
        #f8cecc 3px
    );
    padding: 10px;
}

.elementButton {
    position: absolute;
    right: 45px;
}

#headerMCfooter {
    background-color: white;
    padding: 15px;
}

:root {
    --light: 300;
    --regular: 400;
    --medium: 500;
    --bold: 600;
    --extra-bold: 700;
    font-family: 'Poppins', serif;
}

body {
    line-height: 1.35 !important;
    font-family: 'Poppins', serif;
}

.makeStyles-appAuth-2 .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 0px) scale(0.75) !important;
}

.tableMenu:hover {
    background: #96c7ef !important;
}

.site table tbody tr:hover {
    --bs-table-accent-bg: #96c7ef !important;
}

.tableMenu {
    cursor: pointer;
}
.tableMenu .MuiListItemIcon-root {
    vertical-align: -20%;
}

* {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

h1 {
    font-size: 22px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 14px;
}

.prelative {
    position: relative !important;
}

.searchPageInfo {
    margin-top: -10px !important;
    margin-right: 10px;
    right: 0;
    display: block;
    background: white;
    position: absolute;
}

.modalBody {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
}

.headerMC {
    background-color: white;
    padding: 15px;
    position: fixed;
    width: 80%;
    z-index: 999;
    margin-left: 1px !important;
    border: 1px solid #ddd;
    background: -moz-linear-gradient(top, #eee, #fff);
    background: -webkit-linear-gradient(top, #eee, #fff);
    background: -ms-linear-gradient(top, #eee, #fff);
    background: -o-linear-gradient(top, #eee, #fff);
    height: 95px;
    margin-top: -30px !important;
}

.formMC {
    margin-top: 65px !important;
}

.bi-tsunami-color {
    fill: green;
}

.bi-tsunami-color-red {
    fill: red;
}

.bi-tsunami-color-gray {
    fill: lightgray;
}

.modal-footer {
    justify-content: unset !important;
    display: block !important;
}

.modal-footer .btn .btn-success {
    float: right !important;
}

.versandLabel {
    white-space: break-spaces;
    word-break: break-word;
}

.inputProvision {
    /* border: 0 !important;*/
    width: 35px !important;
    display: inline-block !important;
    font-size: 1.5rem !important;
    padding: 0 !important;
    padding-left: 5px !important;
}

.elementButton {
    position: absolute;
    right: 45px;
}

.dashboard {
    background-color: white;
    padding: 15px;
    width: 100%;
    margin-left: 1px !important;
    border: 1px solid #ddd;
}

.dashboard a {
    text-decoration: none;
    color: #333;
    -webkit-transition: color 0.125s ease-in-out;
    transition: color 0.125s ease-in-out;
}

.dashboard a:hover {
    color: red;
}

.buttonOrder {
    margin-left: 10px !important;
}

.elementsIMg {
    width: 100%;
    height: auto;
    margin-right: 20px;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
}

.elementsIMgHExagon {
    width: 250px !important;
    height: 200px !important;
    margin-right: 20px;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
}

.elementsIMghidden {
    visibility: hidden;
}

.elementActive {
    padding: 10px;
}

.elementDeactive {
    background: repeating-linear-gradient(
        45deg,
        #f8cecc,
        #fff 3px,
        #fff 3px,
        #f8cecc 3px
    );
    padding: 10px;
}
.customizerOption {
    display: none;
}

.merchantTitle {
    width: 1440px !important;
    height: 480px !important;
}

.cardsImg {
    max-width: 100%;
    min-width: 100%;
    height: 360px !important;
    max-height: 360px !important;
    object-fit: cover;
}

#preJson {
    white-space: pre-wrap;
    background: black;
    color: white;
    line-height: 25px;
    max-height: 210px;
    height: 210px;
}

.cage {
    position: absolute;
    width: 100%;

    bottom: 0;
    top: 0;
    background: repeating-linear-gradient(
        90deg,
        transparent,
        transparent 80px,
        #6f7376 80px,
        #181617 100px
    );
    z-index: 2;
    animation: close 3s linear;
}
.cageH1 {
    position: fixed;
    z-index: 1;
    font-size: 12em;
    color: #000;
    margin: auto;
    top: 0;
    left: 350px;
    right: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    z-index: 1;
    background-color: #e4e4e1;
    background-image: radial-gradient(
            at top center,
            rgba(255, 255, 255, 0.03) 0%,
            rgba(0, 0, 0, 0.03) 100%
        ),
        linear-gradient(
            to top,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(143, 152, 157, 0.6) 100%
        );
    background-blend-mode: normal, multiply;
}
.cageSpan {
    position: relative;
}
.cageSpan:after {
    content: '403 Forbidden';
    position: absolute;
    top: 0;
    bottom: -96px;
    left: 40px;
    transform: scaleY(0.6) rotateX(-75deg) skewX(-10deg);
    transform-origin: 50% 100%;
    opacity: 0.2;
    line-height: 1;
}

@keyframes close {
    0% {
        left: -75%;
    }
    100% {
        left: 0%;
    }
}

.modal {
    z-index: 1200 !important;
}

.scrollbar-overlay {
    margin: -30px -32px 0;
    height: 100%;
    color: black;
    text-align: center;
    font-size: 50px;
    top: 0;
    left: 0;
    background-color: lightgray;
    opacity: 0.5;
    z-index: 200;
    padding: 20% 44%;
}

.loaderBucket {
    border-top: 16px solid blue;
    border-right: 16px solid green;
    border-bottom: 16px solid red;
    border-left: 16px solid pink;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-top: 10px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ContentWrapper {
    background: white;
    padding: 15px;
    border-radius: 15px;
}

.CRMBackTop {
    margin-right: 10px;
    background-color: white !important;
    border: 1px solid black !important;
}

.mr-10 {
    margin-right: 10px;
}

.card-link,
.card-link:hover {
    text-decoration: none;
    color: black;
}

.ticketAvatar {
    font-size: 17px !important;
    float: right !important;
    width: 25px !important;
    height: 25px !important;
}

.ticketAvatarFloatLeft {
    font-size: 17px !important;
    width: 25px !important;
    float: left;
    height: 25px !important;
}

.pAvatar {
    font-size: 17px !important;
    height: 25px !important;
    margin-left: 30px;
}

.IssueMenu {
    border: 1px solid rgb(219, 219, 219);
    background: rgb(250, 250, 250);
    position: fixed;
    height: 86%;
    right: 0;
    top: 75px;
    width: 14.666667% !important;
    overflow-y: scroll;
}

.IssueIcon {
    vertical-align: -30%;
}

.uldisplayNone {
    list-style: none;
}

.PielersBadge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.hidden {
    display: none;
}

.display {
    display: block;
}

.mb10 {
    margin-bottom: 10px;
}

.cpointer {
    cursor: pointer;
}

.modalviewContent {
    overflow: auto;
    position: absolute;
    top: 100px;
    left: 5%;
    width: 60%;
    height: 700px;
    border: 1px solid black;
    border-collapse: collapse;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    color: #495057;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ffffff;
}

.addModal {
    position: fixed;
    top: 0px;
    left: 0%;
    height: 100%;
    width: 100%;
    color: #495057;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ffffff;
    z-index: 9999999999999999999;
}

.closeModal {
    text-align: right;
    padding-right: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.searchPageInfoLeft {
    margin-top: -12px !important;
    margin-right: 10px;
    left: 10px;
    display: block;
    background: white;
    position: absolute;
}

.LabelColorBox {
    border: 1px solid black;
    width: 30px;
    height: 30px;
}

.modalLabeledit {
    position: absolute;
    top: 100px;
    left: 32%;
    width: 350px;
    height: 283px;
    padding: 15px;
    border: 1px solid black;
    border-collapse: collapse;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    color: #495057;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ffffff;
}

.divoverlay {
    margin-bottom: 10px;
    height: 165px;
    padding: 5px;
    z-index: 2;
    opacity: 0.4;
    filter: alpha(opacity=40);
    background-color: #000;
}

.divoverlayactive {
    margin-bottom: 10px;
    height: 165px;
    padding: 5px;
    z-index: 2;
    opacity: 0.4;
    filter: alpha(opacity=40);
    background-color: #198754;
}

.pwhite {
    color: white;
}

#headerMCfooter {
    background-color: white;
    padding: 15px;
}

.boxShadowCard {
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
        0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
}

#chatApp {
    /*  position: relative;
      overflow: hidden;
      top: 0px;
      height: calc(100% - 38px);
      margin: auto;
      padding: 0;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);*/
}

#chatApp span {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#chatApp .fa-2x {
    font-size: 1.5em;
}

#chatApp .app-one {
    background-color: #f7f7f7;
    height: 845px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

#chatApp .side {
    padding: 0;
    margin: 0;
    height: 100%;
}
#chatApp .side-one {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    display: block;
    top: 0;
}

#chatApp .side-two {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    position: relative;
    top: -100%;
    left: -100%;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease;
}

#chatApp .heading {
    padding: 10px 16px 10px 15px;
    margin: 0;
    height: 60px;
    width: 100%;
    background-color: #eee;
    z-index: 1000;
}

#chatApp .heading-avatar {
    padding: 0;
    cursor: pointer;
}

#chatApp .heading-avatar-icon img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

#chatApp .heading-name {
    padding: 0 !important;
    cursor: pointer;
}

#chatApp .heading-name-meta {
    font-weight: 700;
    font-size: 100%;
    padding: 5px;
    padding-bottom: 0;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    display: block;
}
#chatApp .heading-online {
    display: none;
    padding: 0 5px;
    font-size: 12px;
    color: #93918f;
}
#chatApp .heading-compose {
    padding: 0;
}

#chatApp .heading-compose i {
    text-align: center;
    padding: 5px;
    color: #93918f;
    cursor: pointer;
}

#chatApp .heading-dot {
    padding: 0;
    margin-left: 10px;
}

#chatApp .heading-dot i {
    text-align: right;
    padding: 5px;
    color: #93918f;
    cursor: pointer;
}

#chatApp .searchBox {
    padding: 0 !important;
    margin: 0 !important;
    height: 60px;
    width: 100%;
}

#chatApp .searchBox-inner {
    height: 100%;
    width: 100%;
    padding: 10px !important;
    background-color: #fbfbfb;
}

/*#searchBox-inner input {
  box-shadow: none;
}*/

#chatApp .searchBox-inner input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

#chatApp .sideBar {
    padding: 0 !important;
    margin: 0 !important;
    background-color: #fff;
    overflow-y: auto;
    border: 1px solid #f7f7f7;
    height: calc(100% - 120px);
}

#chatApp .sideBar-body {
    position: relative;
    padding: 10px !important;
    border-bottom: 1px solid #f7f7f7;
    height: 72px;
    margin: 0 !important;
    cursor: pointer;
}

#chatApp .sideBar-body:hover {
    background-color: #f2f2f2;
}

#chatApp .sideBar-avatar {
    text-align: center;
    padding: 0 !important;
}

#chatApp .avatar-icon img {
    border-radius: 50%;
    height: 49px;
    width: 49px;
}

#chatApp .sideBar-main {
    padding: 0 !important;
}

#chatApp .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
}

#chatApp .sideBar-name {
    padding: 10px !important;
}

#chatApp .name-meta {
    font-size: 100%;
    padding: 1% !important;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
}

#chatApp .sideBar-time {
    padding: 10px !important;
}

#chatApp .time-meta {
    text-align: right;
    font-size: 12px;
    padding: 1% !important;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
}

/*New Message*/

#chatApp .newMessage {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    position: relative;
    left: -100%;
}
#chatApp .newMessage-heading {
    padding: 10px 16px 10px 15px !important;
    margin: 0 !important;
    height: 100px;
    width: 100%;
    background-color: #00bfa5;
    z-index: 1001;
}

#chatApp .newMessage-main {
    padding: 10px 16px 0 15px !important;
    margin: 0 !important;
    height: 60px;
    margin-top: 30px !important;
    width: 100%;
    z-index: 1001;
    color: #fff;
}

#chatApp .newMessage-title {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 5px !important;
}
#chatApp .newMessage-back {
    text-align: center;
    vertical-align: baseline;
    padding: 12px 5px !important;
    display: block;
    cursor: pointer;
}
#chatApp .newMessage-back i {
    margin: auto !important;
}

#chatApp .composeBox {
    padding: 0 !important;
    margin: 0 !important;
    height: 60px;
    width: 100%;
}

#chatApp .composeBox-inner {
    height: 100%;
    width: 100%;
    padding: 10px !important;
    background-color: #fbfbfb;
}

#chatApp .composeBox-inner input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

#chatApp .compose-sideBar {
    padding: 0 !important;
    margin: 0 !important;
    background-color: #fff;
    overflow-y: auto;
    border: 1px solid #f7f7f7;
    height: calc(100% - 160px);
}

/*Conversation*/

#chatApp .conversation {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    /*width: 100%;*/
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    /*overflow-y: auto;*/
}

#chatApp .message {
    padding: 0 !important;
    margin: 0 !important;
    /*background: url("w.jpg") no-repeat fixed center;*/
    background-size: cover;
    overflow-y: auto;
    border: 1px solid #f7f7f7;
    height: calc(100% - 120px);
}
#chatApp .message-previous {
    margin: 0 !important;
    padding: 0 !important;
    height: auto;
    width: 100%;
}
#chatApp .previous {
    font-size: 15px;
    text-align: center;
    padding: 10px !important;
    cursor: pointer;
}

#chatApp .previous a {
    text-decoration: none;
    font-weight: 700;
}

#chatApp .message-body {
    /*margin: 0 !important;
    padding: 0 !important;*/
    width: 100%;
    height: 55px;
    margin-top: 25px;
}

#chatApp .message-main-receiver {
    /*padding: 10px 20px;*/
    max-width: 60%;
}

#chatApp .message-main-sender {
    padding: 3px 20px !important;
    margin-left: 40% !important;
    max-width: 60%;
}

#chatApp .message-text {
    margin: 0 !important;
    padding: 5px !important;
    word-wrap: break-word;
    font-weight: 200;
    font-size: 14px;
    padding-bottom: 0 !important;
}

#chatApp .message-time {
    margin: 0 !important;
    margin-left: 50px !important;
    font-size: 12px;
    text-align: right;
    color: #9a9a9a;
}

#chatApp .receiver {
    width: auto !important;
    padding: 4px 10px 7px !important;
    border-radius: 10px 10px 10px 0;
    background: #ffffff;
    font-size: 12px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;
    display: inline-block;
}

#chatApp .sender {
    float: right;
    width: auto !important;
    background: #dcf8c6;
    border-radius: 10px 10px 0 10px;
    padding: 4px 10px 7px !important;
    font-size: 12px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    display: inline-block;
    word-wrap: break-word;
}

/*Reply*/

#chatApp .reply {
    height: 60px;
    width: 100%;
    background-color: #f5f1ee;
    padding: 10px 5px 10px 5px !important;
    margin: 0 !important;
    z-index: 1000;
}

#chatApp .reply-emojis {
    padding: 5px !important;
}

#chatApp .reply-emojis i {
    text-align: center;
    padding: 5px 5px 5px 5px !important;
    color: #93918f;
    cursor: pointer;
}

#chatApp .reply-recording {
    padding: 5px !important;
}

#chatApp .reply-recording i {
    text-align: center;
    padding: 5px !important;
    color: #93918f;
    cursor: pointer;
}

#chatApp .reply-send {
    padding: 5px !important;
}

#chatApp .reply-send i {
    text-align: center;
    padding: 5px !important;
    color: #93918f;
    cursor: pointer;
}

#chatApp .reply-main {
    padding: 2px 5px !important;
}

#chatApp .reply-main textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
    padding: 5px !important;
    outline: none;
    border: none;
    text-indent: 5px;
    box-shadow: none;
    height: 100%;
    font-size: 16px;
}

#chatApp .reply-main textarea:focus {
    outline: none;
    border: none;
    text-indent: 5px;
    box-shadow: none;
}

@media screen and (max-width: 700px) {
    #chatApp {
        top: 0;
        height: 100%;
    }
    #chatApp .heading {
        height: 70px;
        background-color: #009688;
    }
    #chatApp .fa-2x {
        font-size: 2.3em !important;
    }
    #chatApp .heading-avatar {
        padding: 0 !important;
    }
    #chatApp .heading-avatar-icon img {
        height: 50px;
        width: 50px;
    }
    #chatApp .heading-compose {
        padding: 5px !important;
    }
    #chatApp .heading-compose i {
        color: #fff;
        cursor: pointer;
    }
    #chatApp .heading-dot {
        padding: 5px !important;
        margin-left: 10px !important;
    }
    #chatApp .heading-dot i {
        color: #fff;
        cursor: pointer;
    }
    #chatApp .sideBar {
        height: calc(100% - 130px);
    }
    #chatApp .sideBar-body {
        height: 80px;
    }
    #chatApp .sideBar-avatar {
        text-align: left;
        padding: 0 8px !important;
    }
    #chatApp .avatar-icon img {
        height: 55px;
        width: 55px;
    }
    #chatApp .sideBar-main {
        padding: 0 !important;
    }
    #chatApp .sideBar-main .row {
        padding: 0 !important;
        margin: 0 !important;
    }
    #chatApp .sideBar-name {
        padding: 10px 5px !important;
    }
    #chatApp .name-meta {
        font-size: 16px;
        padding: 5% !important;
    }
    #chatApp .sideBar-time {
        padding: 10px !important;
    }
    #chatApp .time-meta {
        text-align: right;
        font-size: 14px;
        padding: 4% !important;
        color: rgba(0, 0, 0, 0.4);
        vertical-align: baseline;
    }
    /*Conversation*/
    #chatApp .conversation {
        padding: 0 !important;
        margin: 0 !important;
        height: 100%;
        /*width: 100%;*/
        border-left: 1px solid rgba(0, 0, 0, 0.08);
        /*overflow-y: auto;*/
    }
    #chatApp .message {
        height: calc(100% - 140px);
    }
    #chatApp .reply {
        height: 70px;
    }
    #chatApp .reply-emojis {
        padding: 5px 0 !important;
    }
    #chatApp .reply-emojis i {
        padding: 5px 2px !important;
        font-size: 1.8em !important;
    }
    #chatApp .reply-main {
        padding: 2px 8px !important;
    }
    #chatApp .reply-main textarea {
        padding: 8px !important;
        font-size: 18px;
    }
    #chatApp.reply-recording {
        padding: 5px 0 !important;
    }
    #chatApp .reply-recording i {
        padding: 5px 0 !important;
        font-size: 1.8em !important;
    }
    #chatApp .reply-send {
        padding: 5px 0 !important;
    }
    #chatApp .reply-send i {
        padding: 5px 2px 5px 0 !important;
        font-size: 1.8em !important;
    }

    .vh80 {
        height: 80vh;
    }
}

.form-switch .form-check-input {
    margin-left: 0em !important;
}

.page-item.active .page-link {
    z-index: unset !important;
}

.ga-name {
    background-color: #333;
    opacity: 0.6;
    bottom: -5px;
    color: #fff;
    font-size: 13px;
    height: auto;
    left: 0;
    padding: 5px 10px;
    position: absolute;
    width: 97%;
    z-index: 1;
}

.ga-item-img {
    border-radius: 4px;
    cursor: pointer;
    display: block;
    height: 150px;
    margin: 0;
    outline: 0;
    position: relative;
    width: 150px;
}

.ga-item,
.col .ga-item {
    max-width: 150px;
    height: 155px;
    margin: 15px;
    border: 2px solid lightgray;
    padding: 0;
}

.col .ga-item img,
.ga-item img {
    height: 155px;
    width: 146px;
}

.row .ga-options,
.ga-options {
    right: 0px;
    width: 250px;
    max-width: 250px;
    background: #f6f7f7;
    height: 100%;
}

.ga-img-holder {
    height: 100%;
}

.ga-h-100 {
    height: 100%;
}

.sun-editor {
    font-family: var(--bs-body-font-family) !important;
    font-size: 15px !important;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
    font-family: var(--bs-body-font-family) !important;
    font-size: 15px !important;
}

#Favicon_Image {
    width: auto !important;
}

.htmlFace {
    position: relative;
    height: 80vh;
    color: white;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    overflow-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
}
.editorTextarea {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    overflow-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    -webkit-hyphens: none;
    hyphens: none;
    margin: 3px 0px 0.5em 0px;
    overflow: auto;
    height: 79vh !important;
    position: absolute;
    width: 82% !important;
    background: transparent !important;
    padding: 16px 20px 20px 20px !important;
    color: transparent !important;
    caret-color: white !important;
    border: 0 !important;
}

.editorTextareaModul {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
    font-size: 1em !important;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    overflow-wrap: normal;
    line-height: 1.5 !important;
    tab-size: 4;
    -webkit-hyphens: none !important;
    hyphens: none;
    overflow: auto;
    height: 79vh !important;
    width: 99.7% !important;
    background: transparent !important;
    color: transparent !important;
    caret-color: white !important;
    margin: 0.5em 0px;
    padding-top: 13px !important;
    padding-left: 19px !important;
}

.hexacard {
    border-radius: 10px !important;
    padding: 15px;
}
