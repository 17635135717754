.colorpadtop {
    background-color: white;
    padding-top: 15px;
}
/**
 * Zeile 11 (Oben)
 * Zeile 13 (Unten)
 */

.borifloat {
    float: right;
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 250px;
}
