.pro-sidebar-content {
    background: linear-gradient(180deg, #135e7e, #103b5b 35%, #015b91);
}

.MenuItem {
    color: black !important;
}

.tooltips .material-icons {
    color: #015b91;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
    background-color: #a2d9f7;
    border-color: #a2d9f7;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #a2d9f7;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover {
    color: #015b91;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: hidden;
}

.pro-sidebar-content {
    overflow-y: scroll;
}
.css-1wvake5 {
    color: #adadad;
    height: 100%;
    min-width: 270px;
    position: relative;
    text-align: left;
    transition: width, left, right, 0.3s;
    width: 270px;
    z-index: 1009;
    border-right-width: unset !important;
    border-right-style: unset !important;
    border-color: unset !important;
}

.sidebarFooter {
    /* position: absolute;
    bottom: 0;*/
}

.SidebarContent {
    background: linear-gradient(
            180deg,
            #135e7e,
            #103b5b 35%,
            #015b91
    ) !important;
    height: 88%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.activeSidebar {
    background-color: #a2d9f7;
    border-color: #a2d9f7;
}

.deactiveSidebar .ps-menu-button {
    background-color: unset !important;
    border-color: unset !important;
}
.ps-menu-button:hover {
    background-color: #a2d9f7 !important;
    border-color: #a2d9f7 !important;
}
.sidebarHeader {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
}

.css-dip3t8 {
    background-color: transparent !important;
}
.SidebarLogo {
    max-width: unset !important;
}
