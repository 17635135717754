@media all and (min-width: 480px) {
    .Login {
        padding: 30px;
        margin: 0 auto;
        padding-top: 2vh;
        padding-bottom: 5em;
        max-width: 350px;
        margin-top: 2vh;
        box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%),
        0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
        /* border-radius: 15px;*/
        background: rgb(19, 94, 126);
        background: linear-gradient(
                180deg,
                rgba(19, 94, 126, 1) 0%,
                rgba(16, 59, 91, 1) 35%,
                rgba(1, 91, 145, 1) 100%
        );
    }

    .LoginField {
        padding-top: 0.3vh;
        padding-bottom: 0.3vh;
    }

    .logintext br{
        display: none;
    }

    .logintext {
        color: white;
        text-align: center;
        font-family: 'Days One';
        font-size: 40px;
        padding-top: 1.5vh;
        padding-bottom: 1.5vh;
    }

    .logintext.undertext {
        font-size: 11px;
    }

    #form001 img {
        width: 80%;
    }

    .Login #form001 div {
        text-align: center;
    }
}